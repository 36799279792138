import { Link, NavLink } from "@remix-run/react"
import { menuData } from "../constants/menuData"
import clsx from "clsx"
import { xStateMenuContext } from "../contexts/MenuContext"
import { useContext } from "react"

// import {Subscribe} from "./Subscribe";
// TODO: as far as I can tell, the Subscribe button no longer exists on the site
// If I'm right, this code and the Subscribe.tsx file should be removed
// If I'm wrong, it will need to be refactored very similar to how Share.tsx now works

interface FooterProps {
  currentPage: string
}

export function Footer(props: FooterProps) {
  const { currentPage } = props
  const { menuService } = useContext(xStateMenuContext)
  const { send } = menuService

  return (
    <>
      {/* <Subscribe /> */}
      <section id="footer-pane" role="contentinfo" data-testid="footer-menu">
        <button
          id="close-menu-btn"
          onClick={() => send({ type: "TOGGLE_CONTENT" })}
        >
          Close
        </button>
        <section id="footer">
          <div className="container">
            <div className="col-80">
              <nav>
                <NavLink
                  to="/"
                  className="home"
                  aria-label="Home"
                  onClick={() => send("SHOW_CONTENT")}
                  data-testid="logo-with-link"
                >
                  <span className="visually-hidden">Home</span>
                </NavLink>
                {menuData.map((item) => (
                  <NavLink
                    key={item.key}
                    to={item.url}
                    onClick={() => send("SHOW_CONTENT")}
                    className={clsx("item", {
                      current: item.url === currentPage,
                    })}
                    data-testid={`footer-${item.key}-link`}
                  >
                    {item.label}
                  </NavLink>
                ))}
              </nav>
            </div>
            <div className="col-20">
              <div id="follow-links">
                <Link
                  to="https://www.facebook.com/rymradio"
                  target="_blank"
                  title="Facebook"
                  aria-label="Facebook"
                  data-testid="facebook-link"
                >
                  <img
                    className="social-icon"
                    src="/images/RYM_Facebook_Logo.svg"
                    alt="facebook-icon"
                  />
                  <span className="visually-hidden">Facebook</span>
                </Link>
                <Link
                  to="https://twitter.com/rymradio"
                  target="_blank"
                  title="Twitter"
                  aria-label="Twitter"
                  data-testid="twitter-link"
                >
                  <img
                    className="social-icon"
                    src="/images/RYM_X_Logo.svg"
                    alt="twitter-icon"
                  />
                  <span className="visually-hidden">Twitter</span>
                </Link>
              </div>
            </div>
            <footer className="col-100">
              <span>An Outreach of </span>
              <Link
                to="https://www.ligonier.org"
                target="_blank"
                data-testid="ligonier-link"
              >
                Ligonier
              </Link>
              <span className="copyright"> © {new Date().getFullYear()}</span>
            </footer>
          </div>
        </section>
      </section>
      <noscript>
        <img
          height="1"
          width="1"
          style={{ display: "none" }}
          src="https://www.facebook.com/tr?id=1958004644520947&ev=PageView&noscript=1"
          alt="facebook pixel"
        />
      </noscript>
    </>
  )
}
