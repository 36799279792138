import type { FunctionComponent } from "react"
import { useContext } from "react"
import { useSelector, shallowEqual } from "@xstate/react"
import { xStateMenuContext } from "../contexts/MenuContext"
import clsx from "clsx"

interface MenuWrapperProps {
  children: React.ReactNode
}

export const MenuWrapper: FunctionComponent<MenuWrapperProps> = ({
  children,
}) => {
  const { menuService } = useContext(xStateMenuContext)

  const { showContent } = useSelector(
    menuService,
    ({ context }) => {
      return { showContent: context.showContent }
    },
    shallowEqual,
  )

  return (
    <div
      className={clsx("content-wrapper", { open: showContent })}
      data-testid="menu-wrapper"
    >
      {children}
    </div>
  )
}

export default MenuWrapper
