import React, { createContext } from "react"
import { useInterpret } from "@xstate/react"
import type { InterpreterFrom } from "xstate/lib/types"
import { menuMachine } from "../machines/menuMachine.machine"

interface MenuProviderProps {
  children: React.ReactNode
}

export const xStateMenuContext = createContext({
  menuService: {} as InterpreterFrom<typeof menuMachine>,
})

export const MenuProvider: React.FC<MenuProviderProps> = ({ children }) => {
  // useInterpret creates a service for us to subscribe to the machine.
  // useMachine creates an instance of the machine that is not subscribable.
  const menuService = useInterpret(menuMachine)

  return (
    <xStateMenuContext.Provider value={{ menuService }}>
      {children}
    </xStateMenuContext.Provider>
  )
}
