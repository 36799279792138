import { createMachine, assign } from "xstate"

export interface MenuContext {
  showContent: boolean
}

export type MenuEvent =
  | {
      type: "TOGGLE_CONTENT"
    }
  | {
      type: "SHOW_CONTENT"
    }

export const menuMachine = createMachine<MenuContext, MenuEvent>(
  {
    predictableActionArguments: true,
    id: "menu",
    context: {
      showContent: true,
    },
    initial: "contentVisible",
    states: {
      contentVisible: {
        on: {
          TOGGLE_CONTENT: {
            target: "contentHidden",
            actions: "toggleContent",
          },
        },
      },
      contentHidden: {
        on: {
          TOGGLE_CONTENT: {
            target: "contentVisible",
            actions: "toggleContent",
          },
        },
      },
    },
    on: {
      TOGGLE_CONTENT: {
        actions: "toggleContent",
      },
      SHOW_CONTENT: {
        actions: "showContent",
      },
    },
  },
  {
    actions: {
      showContent: assign(() => {
        return { showContent: true }
      }),
      toggleContent: assign((context: MenuContext) => {
        return { showContent: !context.showContent }
      }),
    },
  },
)

export default menuMachine
