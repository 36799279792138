export const menuData = [
  {
    label: "About",
    url: "/about",
    key: "about",
  },
  {
    label: "Archive",
    url: "/archive",
    key: "archive",
  },
  {
    label: "Stations",
    url: "/stations",
    key: "stations",
  },
  {
    label: "Partnership",
    url: "/partnership",
    key: "partnership",
  },
  {
    label: "Questions",
    url: "/questions",
    key: "questions",
  },
  {
    label: "Contact",
    url: "mailto:feedback@renewingyourmind.org",
    key: "contact",
  },
]
