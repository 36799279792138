import { NavLink } from "@remix-run/react"
import { menuData } from "../constants/menuData"
import clsx from "clsx"
import { useContext } from "react"
import { xStateMenuContext } from "../contexts/MenuContext"
import { Image } from "@unpic/react"

interface HeaderProps {
  currentPage: string
}

export function Header(props: HeaderProps) {
  const { currentPage } = props
  const { menuService } = useContext(xStateMenuContext)
  const { send } = menuService

  return (
    <header id="header">
      <nav aria-label="main navigation">
        <div>
          <NavLink id="identity" to="/">
            <Image
              src="/images/RYM_Wordmark_white.svg"
              alt="Wordmark Logo"
              width={0}
              height={0}
            />
          </NavLink>
        </div>
        <div className="menu-items">
          {menuData.map((item) => (
            <NavLink
              key={item.label}
              to={item.url}
              className={clsx("item", {
                current: item.url === currentPage,
              })}
              data-testid={`header-${item.label}`}
            >
              {item.label}
            </NavLink>
          ))}
          <button
            id="menu-btn"
            onClick={() => send({ type: "TOGGLE_CONTENT" })}
            data-testid="menu-icon"
          >
            Menu
          </button>
        </div>
      </nav>
    </header>
  )
}
